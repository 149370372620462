<template>
  <div class="img">
    <div
      v-if="!picList.length"
      class="empty"
    >
      <span>暂无照片</span>
      
    </div>
    <div
      v-else
      class="pic-list"
    >
      <div
        @click="preview(item)"
        v-for="item in picList"
        :key="item.url"
      >
        <video v-if="item.ext === 'mp4'" :src="item.url" controls style="height:100px;width:200px;"></video>

        <img
          v-else
          class="pic"
          :src="item.url"
        />
      </div>
    </div>
    <div>
      <a-modal
        :visible="previewVisible"
        :footer="null"
        width="50%"
        @cancel="handleCancel"
      >
        <img
          alt="example"
          style="width: 100%"
          :src="previewImage"
        />
      </a-modal>
    </div>
  </div>
</template>

<script>
import api from './api'
export default {
  props: {
    businessId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      picList: [],
      previewVisible: false,
      previewImage: ''
    }
  },
  mounted () {
    this.$nextTick(()=>{
      this.getPicList()
    })
    
  },
  methods: {
    async getPicList () {
      if (this.businessId) {
        const res = await api.getByBiz(this.businessId)
          this.picList = res.data
      } else {
        this.picList = []
      }
    },
    handleCancel () {
      this.previewVisible = false
    },
    preview (item) {
      if (item.ext === 'mp4') {
        return
      }
      this.previewImage = item.url
      this.previewVisible = true
    },
    clearPicList () {
      this.picList = []
    }
  }
}
</script>

<style>
.pic-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.pic {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f4f5;
  border-radius: 5px;
  color: #ccc;
  background-color: #f4f4f5;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
</style>
